<template>
  <div class="app-wrapper">
    <top-header-strip />
    <ss-header />
    <div class="main-container">
      <app-main />
    </div>
    <ss-footer />
  </div>
</template>

<script>
import { AppMain, TopHeaderStrip, SsFooter, SsHeader } from './components'

export default {
  name: 'Layout',
  components: {
    AppMain,
    TopHeaderStrip,
    SsFooter,
    SsHeader
  }
}
</script>

<style lang="scss" scoped>
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  transition: width 0.28s;
}

</style>
