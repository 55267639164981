import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: '/home',
      name: 'Home',
      component: () => import('@/views/home')
    }]
  },
  {
    path: '/about',
    component: Layout,
    children: [{
      path: '/about',
      name: 'About',
      component: () => import('@/views/about')
    }]
  },
  {
    path: '/services',
    component: Layout,
    children: [{
      path: '/services',
      name: 'Services',
      component: () => import('@/views/services')
    }]
  },
  {
    path: '/contact',
    component: Layout,
    children: [{
      path: '/contact',
      name: 'Contact',
      component: () => import('@/views/contact')
    }]
  }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export default router
