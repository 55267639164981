<template>
  <!-- header -->
  <header class="w3l-header">
    <div class="hero-header-11">
      <div class="hero-header-11-content">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-lg navbar-light py-0 px-0">
            <a class="navbar-brand" href="/"><img src="https://o.stjan.cn/assets/images/logo-icon.png" alt="" />珠海市星舰科技有限公司</a>
            <!-- if logo is image enable this
          <a class="navbar-brand" href="#index.jsp">
              <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
          </a> -->
            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
              <span class="navbar-toggler-icon fa icon-close fa-times"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mx-auto">
                <li :class="'nav-item ' + ($route.path === '/home' ? 'active' : '@@about-active')">
                  <el-link
                      class="nav-link"
                      :underline="false"
                      @click="$router.replace('/home')"
                  >
                    首页
                    <span class="sr-only">(current)</span>
                  </el-link>
                </li>
                <li :class="'nav-item ' + ($route.path === '/about' ? 'active' : '@@about-active')">
                  <el-link
                    class="nav-link"
                    :underline="false"
                    @click="$router.replace('/about')"
                  >
                    关于
                    <span class="sr-only">(current)</span>
                  </el-link>
                </li>
                <li :class="'nav-item ' + ($route.path === '/services' ? 'active' : '@@about-active')">
                  <el-link
                      class="nav-link"
                      :underline="false"
                      @click="$router.replace('/services')"
                  >
                    服务
                    <span class="sr-only">(current)</span>
                  </el-link>
                </li>
                <li :class="'nav-item ' + ($route.path === '/contact' ? 'active' : '@@about-active')">
                  <el-link
                      class="nav-link"
                      :underline="false"
                      @click="$router.replace('/contact')"
                  >
                    联系
                    <span class="sr-only">(current)</span>
                  </el-link>
                </li>
              </ul>
              <div class="form-inline ml-lg-3">
                <form action="#search-results" class="form-inline position-relative my-2 my-lg-0">
                  <input class="form-control search" type="search" placeholder="站内搜索..." aria-label="Search" required>
                  <button class="btn btn-search position-absolute" type="submit"><span class="fa fa-search" aria-hidden="true"></span></button>
                </form>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "SsHeader"
}
</script>

<style scoped>

</style>
