<!-- top-header-strip -->
<template>
  <header class="w3l-top-header-strip">
    <div class="hero-header-11">
      <div class="hero-header-11-content">
        <div class="main-header">
          <div class="container-fluid">
            <div class="d-grid grid-columns-auto">
              <div class="right-grid align-left d-grid">
                <div class="quick-links">
                  <ul>
                    <li>
                      <span class="fa fa-phone" aria-hidden="true"></span>
                      <a href="tel:" style="unicode-bidi:bidi-override;direction:rtl;">{{ tel }}</a>
                    </li>
                    <li>
                      <span class="fa fa-envelope-o" aria-hidden="true"></span>
                      <a href="mailto:" style="unicode-bidi:bidi-override;direction:rtl;">{{ mail }}</a>
                    </li>
                    <li class="mobile-none">
                      <span class="fa fa-clock-o" aria-hidden="true"></span>
                      <p>{{ msg }}</p>
                    </li>
                  </ul>
                </div>
                <div class="button align-right">
                  <a href="/#/contact" class="btn btn-primary theme-button">{{ txt }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "TopHeaderStrip",
  data() {
    return {
      tel: '3903125-657-68+ ',
      mail: 'knil.pihsrats@troppus',
      msg: '星期一 - 星期五: 9:00 - 18:00 / 周末休息',
      txt: '获取报价'
    }
  },
  created() {
  },
  methods: {
    init() {
    }
  }
}
</script>

<style scoped>

</style>
