<template>
  <!-- footer -->
  <footer class="w3l-footer-29-main" id="footer">
    <div class="footer-29 py-5">
      <div class="container pb-lg-3">
        <div class="row footer-top-29">
          <div class="col-lg-4 col-md-6 footer-list-29 footer-1 mt-md-4">
            <h1 class="footer-logo"><a class="footer-logo mb-md-3 mb-2" href="#url"><img src="https://o.stjan.cn/assets/images/logo-icon.png" alt="" />珠海市星舰科技有限公司</a></h1>
            <p>https://www.tongfu.net.cn</p>
            <p>https://www.stjan.cn</p>
            <p>https://www.atomicdata.cn</p>
            <p>https://www.xjt.space</p>
            <p>https://www.starship.net.cn</p>
            <p>https://www.starship.link</p>
          </div>
          <div class="col-lg-2 col-md-6 footer-list-29 footer-2 mt-5">
            <h6 class="footer-title-29">发现更多</h6>
            <ul>
              <li><a href="#gallery.html">相册</a></li>
              <li><a href="#blog.html">微博</a></li>
              <li><a href="#landing-single.html">登录</a></li>
              <li><a href="#url">隐私政策</a></li>
              <li><a href="#url">条款</a></li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-6 footer-list-29 footer-3 mt-5">
            <div class="properties">
              <h6 class="footer-title-29">最新微博</h6>
              <a class="d-grid twitter-feed" href="#blog-single.html">
                <img src="https://o.stjan.cn/assets/images/img4.jpg" class="img-fluid" alt="">
                一文读懂工业4.0模式将如何影响世界<br>
                <label class="small"><span class="fa fa-clock-o"></span> Oct 02, 2020</label>
              </a>
              <a class="d-grid twitter-feed" href="#blog-single.html">
                <img src="https://o.stjan.cn/assets/images/img6.jpg" class="img-fluid" alt="">
                迈向工业4.0时代从数字化转型开始<br>
                <label class="small"><span class="fa fa-clock-o"></span> Aug 18, 2020</label>
              </a>
              <a class="d-grid twitter-feed" href="#blog-single.html">
                <img src="https://o.stjan.cn/assets/images/img8.jpg" class="img-fluid" alt="">
                ABB：直面数字化转型的机遇与挑战<br>
                <label class="small"><span class="fa fa-clock-o"></span> Apr 08, 2020</label>
              </a>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 footer-list-29 footer-4 mt-5">
            <h6 class="footer-title-29">快速跳转</h6>
            <ul>
              <li><a href="/#/home">首页</a></li>
              <li><a href="/#/about">关于</a></li>
              <li><a href="/#/services">服务</a></li>
              <li><a href="/#/contact">联系</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="footers14-block" class="py-3">
      <div class="container">
        <div class="footers14-bottom text-center">
          <div class="social">
            <a href="#facebook" class="facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a>
            <a href="#google" class="google"><span class="fa fa-google-plus" aria-hidden="true"></span></a>
            <a href="#twitter" class="twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a>
            <a href="#instagram" class="instagram"><span class="fa fa-instagram" aria-hidden="true"></span></a>
            <a href="#youtube" class="youtube"><span class="fa fa-youtube" aria-hidden="true"></span></a>
          </div>
          <div class="copyright mt-1">
            <p>版权所有 &copy; 2017-{{ thisYear }} 珠海市星舰科技有限公司</p>
            <p><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44040302000348"><img src="https://o.stjan.cn/assets/images/beiantubiao.png"  alt=""/>粤公网安 44040302000348号</a></p>
            <p><a href="http://beian.miit.gov.cn/">粤ICP备06086509号</a></p>
          </div>
        </div>
      </div>
    </div>
    <!-- move top -->
    <button onclick="topFunction()" id="movetop" title="Go to top">
      <span class="fa fa-angle-up" aria-hidden="true"></span>
    </button>
  </footer>
</template>

<script>
export default {
  name: "SsFooter",
  computed: {
    thisYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>
